





























import { Component, Prop, Vue } from 'vue-property-decorator'
import Session, { State } from '../Session'
import Registry from '../Registry'
import ErrorMessage from './ErrorMessage.vue'
import $ from 'jquery'

@Component({
    components: {
        ErrorMessage
    }
})
export default class WebSegmenter extends Vue {
    @Prop() private readonly file!: string;
    @Prop() private readonly id!: string
    @Prop() private readonly registry!: Registry
    @Prop() private readonly error!: string
    @Prop() private readonly streamingToken!: string

    private session = new Session()
    private preview = ''

    private mounted () {
        $(window).on('beforeunload', () => this.session.close(false))
        $(window).on('unload', () => this.session.close(false))
        this.registry.register(this.id, this)
        this.fetchPreview()
    }

    private start(targetSnapshot: null|number = null) {
        this.session.open(this.$refs.streaming_container as Element, this.file, this.streamingToken, targetSnapshot)
    }

    /**
     * This function is invoked when clicking a segmenter-snapshot link
     */
    public gotoSnapshot(snapshot: number) {
      if ( ! this.session.isOpen() ) {
        this.start(snapshot)
      } else {
        this.session.gotoSnapshot(snapshot)
      }
    }

    private fetchPreview () {
        $.ajax({
            method: 'GET',
            cache: true,
            url: '/api.php',
            data: {
                action: 'render-preview',
                file: this.file,
                format: 'json'
            }
        })
            .done((data) => {
                this.preview = data.result
            })
    }

    private get stateMessage () {
        switch (this.session.state) {
        case State.FinallyFailed:
            return 'The application has crashed too often.'
        case State.StartFailed:
            return 'The application could not be started.'
        case State.Exited:
            return 'The application has exited.'
        case State.Crashed:
            return 'The application has crashed.'
        case State.StoppedFailure:
            return 'The application has stopped due to errors.'
        case State.Killing:
            return 'The application is shutting down.'
        case State.Terminating:
            return 'The application is shutting down.'
        }
        return `An unknown error occurred (${this.session.state})`
    }
}
