import WebSegmenter from './components/WebSegmenter.vue'

/**
 * The Registry keeps track of all segmenter embeddings on the current page.
 * It provides a mechanism for components to communicate with each other
 */
export default class Registry {

    private components: Record<string, WebSegmenter> = {}

    public register(id: string, component: WebSegmenter) {
        if ( this.components[id] ) {
            console.error(`Duplicate id "${id}"`)
        }
        this.components[id] = component
    }

    public getComponentById(id: string) {
        return this.components[id]
    }
}