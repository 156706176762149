






import {Vue, Component, Prop} from 'vue-property-decorator'
import ErrorMessage from './ErrorMessage.vue'

@Component({
    components:{
        ErrorMessage
    }
})
export default class ProtectedArea extends Vue {
    @Prop() readonly error!: string
}
