





























import {Vue, Component, Prop} from 'vue-property-decorator'
import translate from '../i18n'

@Component
export default class ErrorMessage extends Vue {
    @Prop() private readonly error!: string
    
    private readonly hasMobileInterface = typeof(MobileInterface) !== 'undefined'

    private t(message: string, ...args: [string]) {
        return translate(message, ...args)
    }

    private buy() {
        MobileInterface!.purchaseUpgrade()
    }

    private refresh() {
        MobileInterface!.refreshLicense()
    }
}
