// These will be injected by streaming-client.js
declare const StreamingClientOptions: any // eslint-disable-line @typescript-eslint/no-explicit-any
declare const StreamingClient: any // eslint-disable-line @typescript-eslint/no-explicit-any

export enum State {
    Initial = 'initial',
    FinallyFailed = 'finally-failed',
    StartFailed = 'start-failed',
    Exited = 'exited',
    Crashed = 'crashed',
    StoppedFailure = 'stopped-failure',
    Terminating = 'terminating',
    Killing = 'killing',
    Operational = 'operational',
    Starting = 'starting'
}

export default class Session {
    public state = State.Initial
    public progress = 0 // only valid during state == State.Operational

    private serverUrl = ''
    private id = ''
    private client: any|null = null // eslint-disable-line @typescript-eslint/no-explicit-any
    private container!: Element
    private file!: string
    private statePollingTimer: null|number = null
    private opened = false
    private streamingToken = ''
    private targetSnapshot: number|null = null

    private static streamingLibsLoaded = false;

    public open( container: Element, file: string, streamingToken: string, targetSnapshot: number|null) {
        if ( this.opened ) {
            console.warn("Already opened")
            return
        }

        this.container = container
        this.file = file
        this.opened = true
        this.streamingToken = streamingToken
        this.targetSnapshot = targetSnapshot

        // start job
        $.ajax({
            method: 'POST',
            url: '/api.php',
            data: {
                action: 'start-segmenter',
                file: this.file,
                token: this.streamingToken,
                format: 'json'
            }
        })
            .done((data) => {
                const result = data.result
                this.serverUrl = result.server
                this.id = result.id
                Session.loadStreamingLibs( this.serverUrl, () => { this.startStatePolling() } )
            })
    }

    public close (async = true) {
        if ( ! this.opened ) {
            console.warn("Already closed")
            return
        }

        this.opened = false
        // Normally oee want to use async=true.
        // However, before the user closes/reloads the window, async=false is needed.
        // Otherwise the ajax call will not be executed.
        $.ajax({
            type: 'GET',
            async: async,
            url: this.serverUrl + '/api/v1/shutdown',
            data: { job_id: this.id } // eslint-disable-line @typescript-eslint/camelcase
        }).done(() => {
            this.stopStatePolling()
            this.client.destruct()
            this.client = null;
            this.enter(State.Initial)
        })
    }

    public gotoSnapshot(idx: number) {
        if ( ! this.opened ) return;
        const snapshots = this.client.snapshots.snapshots;
        if (idx >= 0 && idx < snapshots.length) {
            this.client.socket.send('ctrl restoreSnapshot ' + snapshots[idx].id + "\n")
        }
    }

    public isOpen() {
        return this.opened
    }

    private static loadStreamingLibs(serverUrl: string, onDone: () => void) {
        if ( this.streamingLibsLoaded ) {
            onDone();
            return;
        }
        $.getScript(serverUrl + '/extern/v2.1/iasc_decoder.js').done(() => {
            $.getScript(serverUrl + '/extern/v2.1/streaming-client.js').done(() => {
                this.streamingLibsLoaded = true;
                onDone();
            })
        })
    }

    private startStatePolling () {
        const poll = () => {
            $.getJSON(this.serverUrl + '/api/v1/instanceState', { job_id: this.id }) // eslint-disable-line @typescript-eslint/camelcase
                .done((data) => {
                    if (data.job_status !== this.state) {
                        this.enter(data.job_status)
                    }
                    this.statePollingTimer = setTimeout(poll, 1000)
                })
                .fail(() => {
                    this.enter(State.Initial)
                })
        }
        poll()
    }

    private stopStatePolling () {
        if (this.statePollingTimer) clearTimeout(this.statePollingTimer)
    }

    private enter (newState: State) {
        this.state = newState
        if (this.client) {
            this.client.destruct()
            this.client = null
        }
        $(this.container).empty()
        this.progress = 0

        if (newState === State.Operational) {
            const serverUrl = new URL(this.serverUrl)
            serverUrl.protocol = serverUrl.protocol.replace('http', 'ws') // http -> ws, https -> wss

            const options = StreamingClientOptions.createDefault(
                serverUrl.toString(), this.id, $('<div></div>').appendTo(this.container)
            )
            options.quality.svc = false
            options.quality.profile = 'hq-ll-420'
            options.ui.remainingSpaceY = 0
            options.ui.remainingSpaceX = 0
            this.client = new StreamingClient(options)
            this.client.cbClose = () => { this.close() }
            this.client.cbLoadingProgressChanged = ( progress : number ) => {
                this.progress = progress
                if ( progress > 1  && this.targetSnapshot !== null ) {
                    this.gotoSnapshot(this.targetSnapshot)
                    this.targetSnapshot = null
                }
            };
        }
    }
}
