import Vue from 'vue'
import WebSegmenter from './components/WebSegmenter.vue'
import ProtectedArea from './components/ProtectedArea.vue'
import $ from 'jquery'
import Registry from './Registry'

Vue.config.productionTip = false

/**
 * Mock some Nextcloud functions for the streaming-client.js library.
 * (Whyever it depends on them)
 */
const global = (window as any) // eslint-disable-line @typescript-eslint/no-explicit-any
global.OCA = {}
global.OCA.Segmenter = {
    interactionIdleTimeout: 3000 // milliseconds
}
global.t = function (msg: string) {
    return msg
}

$(document).ready(() => {
    const registry = new Registry()
    $('.segmenter-embedding').each((index, element) => {
        const div = document.createElement('div')
        element.appendChild(div)
        new WebSegmenter({
            propsData: {
                file: $(element).data('file'),
                error: $(element).data('error'),
                id: $(element).attr('id'),
                streamingToken: $(element).data('token'),
                registry: registry
            }
        }).$mount(div)
    })

    $('.segmenter-snapshot').click( (element) => {
        const target = $(element.target).data('target')
        const snapshot = $(element.target).data('snapshot')
        registry.getComponentById(target).gotoSnapshot(snapshot)
    })

    $('.protected').each( (index, element) => {
        const div = document.createElement('div')
        element.appendChild(div)
        new ProtectedArea({
            propsData: {
                error: $(element).data('error')
            }
        }).$mount(div)
    });
})
