/**
 * Mediawiki's translation mechanism for client-side code is rather creepy, therefore we implement
 * our own mini-translation mechanism here
 */

const translations: Record<string, Record<string,string> > = {
    en: {
        "needs_premium": "Please purchase premium to view this content",
        "premium_expired": "Your premium license is expired",
        "buy": "Buy",
        "refresh": "Refresh",
        "unknown_error": "Unknown error (%0)"
    },
    de: {
        "needs_premium": "Erwerben Sie Premium, um diesen Inhalt zu sehen",
        "premium_expired": "Premium ist abgelaufen",
        "buy": "Kaufen",
        "refresh": "Erneuern",
        "unknown_error": "Unbekannter Fehler (%0)"
    }
}

const lang = document.getElementsByTagName('html')[0].lang

export default function translate(key: string, ...args: [string]) {

    let result = key
    if ( translations[lang] && translations[lang][key] ) {
        result = translations[lang][key]
    }

    // perform string interpolation, "%0" is replaced by args[0] and so on
    if ( args.length ) {
        result = result.replace(/%\d+/g, (placeholder: string) => {
            const idx = parseInt(placeholder.substr(1)) //discard the trailing percent sign
            return args[idx]
        })
    }

    return result
}